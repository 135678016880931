<template>
  <div id="league">
    <v-row>
      <v-col
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="offset-lg-2 offset-md-2 offset-sm-0 offset-xs-0"
      >
        <v-card>
          <v-card-text class="position-relative">
            <div class="page-title text-center pt-5 ">
              <h2
                class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center "
              >
                <span class="me-2 pb-5">My League</span>
              </h2>
              <p>
                Create your league and play with friends, leagues can also compete against each
                other.
              </p>
            </div>
            <div>
              <v-form class="multi-col-validation mt-4">
                <v-row class="mb-0 pb-0">
                  <v-col
                    md="8"
                    sm="12"
                    cols="12"
                    class="offset-lg-2 offset-md-2 offset-sm-0"
                  >
                    <h2 class="text-center my-4">
                      League Name
                    </h2>
                    <v-text-field
                      v-model="league.name"
                      maxlength="25"
                      dense
                      outlined
                    ></v-text-field>
                    <h2 class="text-center my-4">
                      Description
                    </h2>
                    <v-text-field
                      v-model="league.desc"
                      maxlength="250"
                      dense
                      outlined
                    ></v-text-field>
                    <h2 class="text-center my-4">
                      League Logo
                    </h2>
                    <v-sheet
                      class="mx-auto"
                      max-width="700"
                    >
                      <v-slide-group
                        v-show="isVisible"
                        ref="slideGroup"
                        show-arrows
                        class="slider"
                      >
                        <v-slide-item
                          v-for="(image, imageIndex) in logos"
                          :key="imageIndex"
                          v-slot="{ active}"
                        >
                          <v-avatar
                            rounded
                            size="80"
                            class="team-avatar-wrapper ma-3"
                            color="white"
                          >
                            <img
                              :key="imageIndex"
                              :input-value="active"
                              active-class="success"
                              class=" ml-auto thumbnail logo-slider"
                              color="white"
                              height="80"
                              max-width="80"
                              lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
                              :src="image"
                              @click="updateLogo(image)"
                            />
                          </v-avatar>
                        </v-slide-item>
                      </v-slide-group>
                    </v-sheet>

                    <div class="mx-auto text-center">
                      <v-btn
                        outlined
                        small
                        class="me-3 mt-7"
                        @click="$refs.refInputEl.click()"
                      >
                        <v-icon class="d-sm-none">
                          {{ icons.mdiCloudUploadOutline }}
                        </v-icon>
                        <span class="d-none d-sm-block caption">Upload logo</span>
                      </v-btn>
                      <input
                        ref="refInputEl"
                        type="file"
                        accept="image/*"
                        :hidden="true"
                        @change="previewImage"
                      />
                      <p class="text-sm mt-2 text-center">
                        Supported formats: JPG, GIF or PNG. Max size of 1MB
                      </p>
                    </div>

                    <p class="text-center my-5 mt-9">
                      <v-avatar
                        rounded
                        size="125"
                        height="125"

                        color="white"
                      >
                        <v-img
                          height="120"
                          max-width="120"
                          :src="league.logo||'https://www.fantasykombat.com/logo_small.png'"
                        />
                      </v-avatar>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0 mb-5">
                  <v-col
                    md="6"
                    sm="12"
                    xs="12"
                    cols="6"
                  >
                    <v-select
                      v-model="league.visibility"
                      outlined
                      class="mt-5"
                      :items="visibilityOptions"
                      label="League Type"
                    ></v-select>

                    <small v-if="league.visibility === 'Public'">
                      Anyone can join your league.</small>
                    <small v-if="league.visibility === 'Private'">
                      Only people you invite can join.
                    </small>
                    <small v-if="league.visibility === 'Hidden'">
                      League will not be shown publicly, requires invite code to join.
                    </small>
                  </v-col>
                  <v-col
                    md="6"
                    sm="12"
                    xs="12"
                    cols="6"
                  >
                    <v-country-select
                      v-model="country"
                      :country-name="false"
                      class="mt-5 country-select"
                      outlined
                      label="Set Country (optional)"
                    />
                    <small>Shows flag but won't restrict who can join.</small>
                  </v-col>
                  <v-col
                    md="12"
                    sm="12"
                    cols="12"
                    class="mt-0 pt-0"
                  >
                    <p
                      v-if="user.profile.admin"
                      class="text-center"
                    >
                      <v-checkbox
                        v-model="league.partner"
                        label="Verified partner"
                      ></v-checkbox>
                      <v-text-field
                        v-model="league.embed1"
                        placeholder="Embed 1"
                        maxlength="500"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="league.embed2"
                        placeholder="Embed 2"
                        maxlength="500"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="league.discord"
                        placeholder="Discord"
                        maxlength="500"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="league.twitter"
                        placeholder="twitter handle"
                        maxlength="250"
                        dense
                        outlined
                      ></v-text-field>
                    </p>
                    <p class="text-center">
                      <v-btn
                        outlined
                        color="success"
                        class="mt-5  mx-2 text-center"
                        @click="saveLeague('active')"
                      >
                        Save League
                      </v-btn>
                      <v-btn
                        outlined
                        color="error"
                        class="mt-5 mx-2 text-center"
                        @click="saveLeague('archived')"
                      >
                        Delete League
                      </v-btn>
                    </p>
                    <v-snackbar
                      v-model="snackbarShow"
                      :color="snackbarType"
                      :absolute="true"
                      :bottom="true"
                      :timeout="snackbarTimeout"
                    >
                      <v-layout
                        align-center
                        pr-4
                      >
                        <v-icon
                          class="pr-3"
                          dark
                          large
                        >
                          {{ snackbarIcon }}
                        </v-icon>
                        <v-layout column>
                          <div>{{ snackbarText }}</div>
                        </v-layout>
                      </v-layout>
                    </v-snackbar>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import dbFunctions from '@/db'

export default {
  data() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      preview: '',
      file: '',
      user: this.$store.state.user,
      team: {},
      league: {},
      leagueId: null,
      logos: '',
      uploadedLogo: false,
      isVisible: true,
      country: '',
      visibilityOptions: ['Public', 'Private', 'Hidden'],
      loading: true,
      snackbarText: '',
      snackbarType: 'error',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',
    }
  },
  watch: {
    country() {
      this.league.country = this.country
    },
    isVisible(val) {
      if (val) this.$refs.slideGroup.setWidths()
    },
  },
  mounted() {
    this.leagueId = this.$route.query.id
    this.getLeague()
    this.getLogos()
    this.getTeams()
  },

  methods: {
    async getTeams() {
      await dbFunctions.getTeam(this.user.uid)
        .then(teamData => {
          this.team = teamData
        })
    },
    async createLeague(pfields) {
      await dbFunctions.createLeague(this.user.uid, this.league)
        .then(data => {
          if (this.team?.leagues) {
            this.team.leagues.push(data.id)
          } else {
            this.team.leagues = [data.id]
          }
          const fields = pfields
          fields.leagues = this.team.leagues
          dbFunctions.updateTeam(this.user.uid, fields)
            .then(() => {
              this.$router.push({path: 'view-league', query: {id: data.id}})

              return data
            })
        })
    },
    async previewImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0]
      this.preview = URL.createObjectURL(this.file)
      this.league.logo = this.preview
      this.uploadedLogo = true
      if (this.uploadedLogo || null) {
        await dbFunctions.fileUpload(`league-logos/${this.league.name}`, this.file.name, this.file).then(logo => {
          console.log('get saved logo')
          console.log(logo)
          console.log(this.league.logo)
        })
      }
    },
    updateLogo(image) {
      this.$set(this.league, 'logo', image)
    },
    getLeague() {
      if (this.leagueId) {
        dbFunctions.getMyLeagues(this.leagueId)
          .then(leagueData => {
            this.league = leagueData.data()
            if (!this.league) {
              this.$router.push('/403')
            }
            if (this.league.country) {
              this.country = this.league.country
            }

            return leagueData
          })
      } else {
        this.league = {
          name: '',
          desc: '',
          status: 'active',
          logo: '',
          visibility: 'Public',
          country: '',
          twitter: '@',
        }
      }
    },
    saveLeague(status) {
      if (this.country) {
        this.league.country = this.country
      } else {
        this.league.country = ''
      }
      const fields = {
        createdBy: this.league.createdBy || this.user.uid,
        logo: this.league.logo,
        name: this.league.name,
        uploadedLogo: this.uploadedLogo,
        desc: this.league.desc,
        country: this.league.country,
        visibility: this.league.visibility,
        partner: this.league?.partner || null,
        embed1: this.league?.embed1 || null,
        embed2: this.league?.embed2 || null,
        twitter: this.league?.twitter || null,
        discord: this.league?.discord || null,
        status: status || this.league.status,
      }
      if (this.leagueId) {
        dbFunctions.updateLeague(this.$store.state.user.uid, this.leagueId, fields)
          .then(() => {
            if (this.league?.name) {
              window.$crisp?.push(['set', 'session:event', [[['edited_league', {name: this.league.name}, 'orange']]]])
            }
            if (status === 'archived') {
              this.snackbarType = 'error'
              this.snackbarShow = true
              this.snackbarText = 'League queued for deletion...'
              setTimeout(() => {
                this.$router.push({path: '/leagues'})
              }, 3000)
            } else {
              this.$router.push({path: 'view-league', query: {id: this.leagueId}})
            }
          })
      } else {
        if (this.league?.name) {
          window.$crisp?.push(['set', 'session:event', [[['created_league', {name: this.league.name}, 'red']]]])
        }
        this.createLeague(fields)
      }
    },
    getLogos() {
      this.loadLogos(require.context('@/assets/images/team-logos/', true, /\.jpg$/))
    },
    loadLogos(r) {
      const logos = []
      r.keys().forEach(key => (logos.push(r(key))))
      this.logos = this.shuffleLogos(logos)
    },
    shuffleLogos(logoList) {
      const array = logoList
      array.sort(() => Math.random() - 0.5) // rough shuffle not truly random

      return array
    },

  },
}
</script>

<style lang="scss">
.logo-slider {
  cursor: pointer;
}

span.league-text-wrapper {
  width: 110px;
  height: 30px;
  display: inline-block;
  margin-top: 6px;
  font-size: 12px;
}

span.league-text {
  text-align: center;
  font-size: 14px;
}

.league-avatar-img {
  margin-top: -35px;
  display: inline-block;
}

.country-select path {
  stroke: #fff;
  fill: #fff;
}
</style>
